/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        /*
        Lightbox for Bootstrap 3 by @ashleydw
        https://github.com/ashleydw/lightbox

        License: https://github.com/ashleydw/lightbox/blob/master/LICENSE
        */


        (function() {
          "use strict";
          var $, EkkoLightbox;

          $ = jQuery;

          EkkoLightbox = function(element, options) {
            var content, footer, header,
              _this = this;
            this.options = $.extend({
              title: null,
              footer: null,
              remote: null
            }, $.fn.ekkoLightbox.defaults, options || {});
            this.$element = $(element);
            content = '';
            this.modal_id = this.options.modal_id ? this.options.modal_id : 'ekkoLightbox-' + Math.floor((Math.random() * 1000) + 1);
            header = '<div class="modal-header"' + (this.options.title || this.options.always_show_close ? '' : ' style="display:none"') + '><button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button><h4 class="modal-title">' + (this.options.title || "&nbsp;") + '</h4></div>';
            footer = '<div class="modal-footer"' + (this.options.footer ? '' : ' style="display:none"') + '>' + this.options.footer + '</div>';
            $(document.body).append('<div id="' + this.modal_id + '" class="ekko-lightbox modal fade" tabindex="-1"><div class="modal-dialog"><div class="modal-content">' + header + '<div class="modal-body"><div class="ekko-lightbox-container"><div></div></div></div>' + footer + '</div></div></div>');
            this.modal = $('#' + this.modal_id);
            this.modal_dialog = this.modal.find('.modal-dialog').first();
            this.modal_content = this.modal.find('.modal-content').first();
            this.modal_body = this.modal.find('.modal-body').first();
            this.lightbox_container = this.modal_body.find('.ekko-lightbox-container').first();
            this.lightbox_body = this.lightbox_container.find('> div:first-child').first();
            this.showLoading();
            this.modal_arrows = null;
            this.border = {
              top: parseFloat(this.modal_dialog.css('border-top-width')) + parseFloat(this.modal_content.css('border-top-width')) + parseFloat(this.modal_body.css('border-top-width')),
              right: parseFloat(this.modal_dialog.css('border-right-width')) + parseFloat(this.modal_content.css('border-right-width')) + parseFloat(this.modal_body.css('border-right-width')),
              bottom: parseFloat(this.modal_dialog.css('border-bottom-width')) + parseFloat(this.modal_content.css('border-bottom-width')) + parseFloat(this.modal_body.css('border-bottom-width')),
              left: parseFloat(this.modal_dialog.css('border-left-width')) + parseFloat(this.modal_content.css('border-left-width')) + parseFloat(this.modal_body.css('border-left-width'))
            };
            this.padding = {
              top: parseFloat(this.modal_dialog.css('padding-top')) + parseFloat(this.modal_content.css('padding-top')) + parseFloat(this.modal_body.css('padding-top')),
              right: parseFloat(this.modal_dialog.css('padding-right')) + parseFloat(this.modal_content.css('padding-right')) + parseFloat(this.modal_body.css('padding-right')),
              bottom: parseFloat(this.modal_dialog.css('padding-bottom')) + parseFloat(this.modal_content.css('padding-bottom')) + parseFloat(this.modal_body.css('padding-bottom')),
              left: parseFloat(this.modal_dialog.css('padding-left')) + parseFloat(this.modal_content.css('padding-left')) + parseFloat(this.modal_body.css('padding-left'))
            };
            this.modal.on('show.bs.modal', this.options.onShow.bind(this)).on('shown.bs.modal', function() {
              _this.modal_shown();
              return _this.options.onShown.call(_this);
            }).on('hide.bs.modal', this.options.onHide.bind(this)).on('hidden.bs.modal', function() {
              if (_this.gallery) {
                $(document).off('keydown.ekkoLightbox');
              }
              _this.modal.remove();
              return _this.options.onHidden.call(_this);
            }).modal('show', options);
            return this.modal;
          };

          EkkoLightbox.prototype = {
            modal_shown: function() {
              var video_id,
                _this = this;
              if (!this.options.remote) {
                return this.error('No remote target given');
              } else {
                this.gallery = this.$element.data('gallery');
                if (this.gallery) {
                  if (this.options.gallery_parent_selector === 'document.body' || this.options.gallery_parent_selector === '') {
                    this.gallery_items = $(document.body).find('*[data-toggle="lightbox"][data-gallery="' + this.gallery + '"]');
                  } else {
                    this.gallery_items = this.$element.parents(this.options.gallery_parent_selector).first().find('*[data-toggle="lightbox"][data-gallery="' + this.gallery + '"]');
                  }
                  this.gallery_index = this.gallery_items.index(this.$element);
                  $(document).on('keydown.ekkoLightbox', this.navigate.bind(this));
                  if (this.options.directional_arrows && this.gallery_items.length > 1) {
                    this.lightbox_container.append('<div class="ekko-lightbox-nav-overlay"><a href="#" class="' + this.strip_stops(this.options.left_arrow_class) + '"></a><a href="#" class="' + this.strip_stops(this.options.right_arrow_class) + '"></a></div>');
                    this.modal_arrows = this.lightbox_container.find('div.ekko-lightbox-nav-overlay').first();
                    this.lightbox_container.find('a' + this.strip_spaces(this.options.left_arrow_class)).on('click', function(event) {
                      event.preventDefault();
                      return _this.navigate_left();
                    });
                    this.lightbox_container.find('a' + this.strip_spaces(this.options.right_arrow_class)).on('click', function(event) {
                      event.preventDefault();
                      return _this.navigate_right();
                    });
                  }
                }
                if (this.options.type) {
                  if (this.options.type === 'image') {
                    return this.preloadImage(this.options.remote, true);
                  } else if (this.options.type === 'youtube' && (video_id = this.getYoutubeId(this.options.remote))) {
                    return this.showYoutubeVideo(video_id);
                  } else if (this.options.type === 'vimeo') {
                    return this.showVimeoVideo(this.options.remote);
                  } else if (this.options.type === 'instagram') {
                    return this.showInstagramVideo(this.options.remote);
                  } else if (this.options.type === 'url') {
                    return this.loadRemoteContent(this.options.remote);
                  } else if (this.options.type === 'video') {
                    return this.showVideoIframe(this.options.remote);
                  } else {
                    return this.error("Could not detect remote target type. Force the type using data-type=\"image|youtube|vimeo|instagram|url|video\"");
                  }
                } else {
                  return this.detectRemoteType(this.options.remote);
                }
              }
            },
            strip_stops: function(str) {
              return str.replace(/\./g, '');
            },
            strip_spaces: function(str) {
              return str.replace(/\s/g, '');
            },
            isImage: function(str) {
              return str.match(/(^data:image\/.*,)|(\.(jp(e|g|eg)|gif|png|bmp|webp|svg)((\?|#).*)?$)/i);
            },
            isSwf: function(str) {
              return str.match(/\.(swf)((\?|#).*)?$/i);
            },
            getYoutubeId: function(str) {
              var match;
              match = str.match(/^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/);
              if (match && match[2].length === 11) {
                return match[2];
              } else {
                return false;
              }
            },
            getVimeoId: function(str) {
              if (str.indexOf('vimeo') > 0) {
                return str;
              } else {
                return false;
              }
            },
            getInstagramId: function(str) {
              if (str.indexOf('instagram') > 0) {
                return str;
              } else {
                return false;
              }
            },
            navigate: function(event) {
              event = event || window.event;
              if (event.keyCode === 39 || event.keyCode === 37) {
                if (event.keyCode === 39) {
                  return this.navigate_right();
                } else if (event.keyCode === 37) {
                  return this.navigate_left();
                }
              }
            },
            navigateTo: function(index) {
              var next, src;
              if (index < 0 || index > this.gallery_items.length - 1) {
                return this;
              }
              this.showLoading();
              this.gallery_index = index;
              this.$element = $(this.gallery_items.get(this.gallery_index));
              this.updateTitleAndFooter();
              src = this.$element.attr('data-remote') || this.$element.attr('href');
              this.detectRemoteType(src, this.$element.attr('data-type') || false);
              if (this.gallery_index + 1 < this.gallery_items.length) {
                next = $(this.gallery_items.get(this.gallery_index + 1), false);
                src = next.attr('data-remote') || next.attr('href');
                if (next.attr('data-type') === 'image' || this.isImage(src)) {
                  return this.preloadImage(src, false);
                }
              }
            },
            navigate_left: function() {
              if (this.gallery_items.length === 1) {
                return;
              }
              if (this.gallery_index === 0) {
                this.gallery_index = this.gallery_items.length - 1;
              } else {
                this.gallery_index--;
              }
              this.options.onNavigate.call(this, 'left', this.gallery_index);
              return this.navigateTo(this.gallery_index);
            },
            navigate_right: function() {
              if (this.gallery_items.length === 1) {
                return;
              }
              if (this.gallery_index === this.gallery_items.length - 1) {
                this.gallery_index = 0;
              } else {
                this.gallery_index++;
              }
              this.options.onNavigate.call(this, 'right', this.gallery_index);
              return this.navigateTo(this.gallery_index);
            },
            detectRemoteType: function(src, type) {
              var video_id;
              type = type || false;
              if (type === 'image' || this.isImage(src)) {
                this.options.type = 'image';
                return this.preloadImage(src, true);
              } else if (type === 'youtube' || (video_id = this.getYoutubeId(src))) {
                this.options.type = 'youtube';
                return this.showYoutubeVideo(video_id);
              } else if (type === 'vimeo' || (video_id = this.getVimeoId(src))) {
                this.options.type = 'vimeo';
                return this.showVimeoVideo(video_id);
              } else if (type === 'instagram' || (video_id = this.getInstagramId(src))) {
                this.options.type = 'instagram';
                return this.showInstagramVideo(video_id);
              } else if (type === 'video') {
                this.options.type = 'video';
                return this.showVideoIframe(video_id);
              } else {
                this.options.type = 'url';
                return this.loadRemoteContent(src);
              }
            },
            updateTitleAndFooter: function() {
              var caption, footer, header, title;
              header = this.modal_content.find('.modal-header');
              footer = this.modal_content.find('.modal-footer');
              title = this.$element.data('title') || "";
              caption = this.$element.data('footer') || "";
              if (title || this.options.always_show_close) {
                header.css('display', '').find('.modal-title').html(title || "&nbsp;");
              } else {
                header.css('display', 'none');
              }
              if (caption) {
                footer.css('display', '').html(caption);
              } else {
                footer.css('display', 'none');
              }
              return this;
            },
            showLoading: function() {
              this.lightbox_body.html('<div class="modal-loading">' + this.options.loadingMessage + '</div>');
              return this;
            },
            showYoutubeVideo: function(id) {
              var height, width;
              width = this.checkDimensions(this.$element.data('width') || 560);
              height = width / (560 / 315);
              return this.showVideoIframe('//www.youtube.com/embed/' + id + '?badge=0&autoplay=1&html5=1', width, height);
            },
            showVimeoVideo: function(id) {
              var height, width;
              width = this.checkDimensions(this.$element.data('width') || 560);
              height = width / (500 / 281);
              return this.showVideoIframe(id + '?autoplay=1', width, height);
            },
            showInstagramVideo: function(id) {
              var height, width;
              width = this.checkDimensions(this.$element.data('width') || 612);
              this.resize(width);
              height = width + 80;
              this.lightbox_body.html('<iframe width="' + width + '" height="' + height + '" src="' + this.addTrailingSlash(id) + 'embed/" frameborder="0" allowfullscreen></iframe>');
              this.options.onContentLoaded.call(this);
              if (this.modal_arrows) {
                return this.modal_arrows.css('display', 'none');
              }
            },
            showVideoIframe: function(url, width, height) {
              height = height || width;
              this.resize(width);
              this.lightbox_body.html('<div class="embed-responsive embed-responsive-16by9"><iframe width="' + width + '" height="' + height + '" src="' + url + '" frameborder="0" allowfullscreenclass="embed-responsive-item"></iframe></div>');
              this.options.onContentLoaded.call(this);
              if (this.modal_arrows) {
                this.modal_arrows.css('display', 'none');
              }
              return this;
            },
            loadRemoteContent: function(url) {
              var disableExternalCheck, width,
                _this = this;
              width = this.$element.data('width') || 560;
              this.resize(width);
              disableExternalCheck = this.$element.data('disableExternalCheck') || false;
              if (!disableExternalCheck && !this.isExternal(url)) {
                this.lightbox_body.load(url, $.proxy(function() {
                  return _this.$element.trigger('loaded.bs.modal');
                }));
              } else {
                this.lightbox_body.html('<iframe width="' + width + '" height="' + width + '" src="' + url + '" frameborder="0" allowfullscreen></iframe>');
                this.options.onContentLoaded.call(this);
              }
              if (this.modal_arrows) {
                this.modal_arrows.css('display', 'none');
              }
              return this;
            },
            isExternal: function(url) {
              var match;
              match = url.match(/^([^:\/?#]+:)?(?:\/\/([^\/?#]*))?([^?#]+)?(\?[^#]*)?(#.*)?/);
              if (typeof match[1] === "string" && match[1].length > 0 && match[1].toLowerCase() !== location.protocol) {
                return true;
              }
              if (typeof match[2] === "string" && match[2].length > 0 && match[2].replace(new RegExp(":(" + {
                "http:": 80,
                "https:": 443
              }[location.protocol] + ")?$"), "") !== location.host) {
                return true;
              }
              return false;
            },
            error: function(message) {
              this.lightbox_body.html(message);
              return this;
            },
            preloadImage: function(src, onLoadShowImage) {
              var img,
                _this = this;
              img = new Image();
              if ((onLoadShowImage === null) || onLoadShowImage === true) {
                img.onload = function() {
                  var image;
                  image = $('<img />');
                  image.attr('src', img.src);
                  image.addClass('img-responsive');
                  _this.lightbox_body.html(image);
                  if (_this.modal_arrows) {
                    _this.modal_arrows.css('display', 'block');
                  }
                  return image.load(function() {
                    _this.resize(img.width);
                    return _this.options.onContentLoaded.call(_this);
                  });
                };
                img.onerror = function() {
                  return _this.error('Failed to load image: ' + src);
                };
              }
              img.src = src;
              return img;
            },
            resize: function(width) {
              var width_total;
              width_total = width + this.border.left + this.padding.left + this.padding.right + this.border.right;
              this.modal_dialog.css('width', 'auto').css('max-width', width_total);
              this.lightbox_container.find('a').css('line-height', function() {
                return $(this).parent().height() + 'px';
              });
              return this;
            },
            checkDimensions: function(width) {
              var body_width, width_total;
              width_total = width + this.border.left + this.padding.left + this.padding.right + this.border.right;
              body_width = document.body.clientWidth;
              if (width_total > body_width) {
                width = this.modal_body.width();
              }
              return width;
            },
            close: function() {
              return this.modal.modal('hide');
            },
            addTrailingSlash: function(url) {
              if (url.substr(-1) !== '/') {
                url += '/';
              }
              return url;
            }
          };

          $.fn.ekkoLightbox = function(options) {
            return this.each(function() {
              var $this;
              $this = $(this);
              options = $.extend({
                remote: $this.attr('data-remote') || $this.attr('href'),
                gallery_parent_selector: $this.attr('data-parent'),
                type: $this.attr('data-type')
              }, options, $this.data());
              new EkkoLightbox(this, options);
              return this;
            });
          };

          $.fn.ekkoLightbox.defaults = {
            gallery_parent_selector: 'document.body',
            left_arrow_class: '.glyphicon .glyphicon-chevron-left',
            right_arrow_class: '.glyphicon .glyphicon-chevron-right',
            directional_arrows: true,
            type: null,
            always_show_close: true,
            loadingMessage: 'Loading...',
            onShow: function() {},
            onShown: function() {},
            onHide: function() {},
            onHidden: function() {},
            onNavigate: function() {},
            onContentLoaded: function() {}
          };

        }).call(this);
      },
      finalize: function() {
        jQuery(document).delegate('*[data-toggle="lightbox"]', 'click', function(event) {
            event.preventDefault();
            jQuery(this).ekkoLightbox();
        });
        jQuery(".gallery-thumbs a.thumb").click(function( event ) {
            event.preventDefault();
            var original = jQuery(this).attr('href');
            var zoom = jQuery(this).data('zoom');
            jQuery(this).parent('li').addClass('active').siblings().removeClass('active');
            jQuery(this).closest('.gallery').find('.zoom a').attr('href', original);
            jQuery(this).closest('.gallery').find('.zoom a img').attr('src', zoom);
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        jQuery('.readmore').hover(
          function () {
            jQuery('.blog-column p').css('font-weight', 'bold');
          },
          function () {
            jQuery('.blog-column p').css('font-weight', 'normal');
          }
        );
        /*jshint browser:true */
        /*!
        * FitVids 1.1
        *
        * Copyright 2013, Chris Coyier - http://css-tricks.com + Dave Rupert - http://daverupert.com
        * Credit to Thierry Koblentz - http://www.alistapart.com/articles/creating-intrinsic-ratios-for-video/
        * Released under the WTFPL license - http://sam.zoy.org/wtfpl/
        *
        */

        ;(function( $ ){

          'use strict';

          $.fn.fitVids = function( options ) {
            var settings = {
              customSelector: null,
              ignore: null
            };

            if(!document.getElementById('fit-vids-style')) {
              // appendStyles: https://github.com/toddmotto/fluidvids/blob/master/dist/fluidvids.js
              var head = document.head || document.getElementsByTagName('head')[0];
              var css = '.fluid-width-video-wrapper{width:100%;position:relative;padding:0;}.fluid-width-video-wrapper iframe,.fluid-width-video-wrapper object,.fluid-width-video-wrapper embed {position:absolute;top:0;left:0;width:100%;height:100%;}';
              var div = document.createElement("div");
              div.innerHTML = '<p>x</p><style id="fit-vids-style">' + css + '</style>';
              head.appendChild(div.childNodes[1]);
            }

            if ( options ) {
              $.extend( settings, options );
            }

            return this.each(function(){
              var selectors = [
                'iframe[src*="player.vimeo.com"]',
                'iframe[src*="youtube.com"]',
                'iframe[src*="youtube-nocookie.com"]',
                'iframe[src*="kickstarter.com"][src*="video.html"]',
                'object',
                'embed'
              ];

              if (settings.customSelector) {
                selectors.push(settings.customSelector);
              }

              var ignoreList = '.fitvidsignore';

              if(settings.ignore) {
                ignoreList = ignoreList + ', ' + settings.ignore;
              }

              var $allVideos = $(this).find(selectors.join(','));
              $allVideos = $allVideos.not('object object'); // SwfObj conflict patch
              $allVideos = $allVideos.not(ignoreList); // Disable FitVids on this video.

              $allVideos.each(function(count){
                var $this = $(this);
                if($this.parents(ignoreList).length > 0) {
                  return; // Disable FitVids on this video.
                }
                if (this.tagName.toLowerCase() === 'embed' && $this.parent('object').length || $this.parent('.fluid-width-video-wrapper').length) { return; }
                if ((!$this.css('height') && !$this.css('width')) && (isNaN($this.attr('height')) || isNaN($this.attr('width'))))
                {
                  $this.attr('height', 9);
                  $this.attr('width', 16);
                }
                var height = ( this.tagName.toLowerCase() === 'object' || ($this.attr('height') && !isNaN(parseInt($this.attr('height'), 10))) ) ? parseInt($this.attr('height'), 10) : $this.height(),
                    width = !isNaN(parseInt($this.attr('width'), 10)) ? parseInt($this.attr('width'), 10) : $this.width(),
                    aspectRatio = height / width;
                if(!$this.attr('id')){
                  var videoID = 'fitvid' + count;
                  $this.attr('id', videoID);
                }
                $this.wrap('<div class="fluid-width-video-wrapper"></div>').parent('.fluid-width-video-wrapper').css('padding-top', (aspectRatio * 100)+'%');
                $this.removeAttr('height').removeAttr('width');
              });
            });
          };
        // Works with either jQuery or Zepto
        })( window.jQuery || window.Zepto );
        jQuery(document).ready(function(){
          // Target your .container, .wrapper, .post, etc.
          jQuery("#video-header, #video-header-mobile").fitVids();
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'contact': {
      init: function() {
        jQuery(function() {
          jQuery("#label_1_5_1").prepend('<span><span></span></span>');
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
